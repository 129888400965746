import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import SupportLeftNav from "../../../components/TwoColumnLeftNav/SupportLeftNav";
import LocalizedLink from "../../../components/localizedLink";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { documents, emissivity } = useTranslations();

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>{documents}</h2>
					<div className="content-container">
						<ul className="documents-ul flex wrap">
							<li className="documents-li ">
								<LocalizedLink to={`/support/documents/emissivity/`}>
									{emissivity}
								</LocalizedLink>
							</li>

							<li className="documents-li">
								<LocalizedLink
									to={`/support/documents/about-temperature/temp01/`}
								>
									温度ってなんだろう
								</LocalizedLink>
							</li>
							<li className="documents-li">
								<LocalizedLink to={`/support/documents/catchall/`}>
									弊社製品を輸出されるお客様へのお知らせ：キャッチオール規制
								</LocalizedLink>
							</li>
							<li className="documents-li">
								<LocalizedLink to={`/support/documents/bimetal/`}>
									バイメタル式温度計（在庫規格品）図面
								</LocalizedLink>
							</li>
							<li className="documents-li">
								<LocalizedLink
									to={`/support/documents/remote-measurement-type/`}
								>
									隔測式温度計（在庫規格品）図面
								</LocalizedLink>
							</li>
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
